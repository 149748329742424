/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        if ( !Cookies.get('alert') && $('.alert-banner').length ) {
          $('.alert-banner').addClass('visible');

          $('#dismissBtn').click(function(e) {
            e.preventDefault();
            e.stopPropagation();

            $('.alert-banner').removeClass('visible');
            Cookies.set('alert', false, {
              expires: 1
            });
          });
        }

        // toggles hamburger and nav open and closed states
        var removeClass = true;
        $(".hamburger").click(function () {
          $(".hamburger").toggleClass('is-active');
          $("#sideNav").toggleClass('sideNav-open');
          $(".sideNavBody").toggleClass('sideNavBody-push');
          removeClass = false;
        });

        $(".sideNav").click(function() {
          removeClass = false;
        });

        document.addEventListener('touchstart', function(e) {
          if (removeClass && !$(e.target).hasClass('sideNav') && $('.sideNav').has($(e.target)).length === 0) {
             $(".hamburger").removeClass('is-active');
             $("#sideNav").removeClass('sideNav-open');
             $(".sideNavBody").removeClass('sideNavBody-push');
          }
          removeClass = true;
        }, false);

        $(window).scroll(function () {
          if ($(this).scrollTop() > 100) {
            $('.scrollup').fadeIn();
          } else {
            $('.scrollup').fadeOut();
          }
        });

        $('.scrollup').click(function () {
          $("html, body").animate({
            scrollTop: 0
          }, 600);
          return false;
        });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        if( $('.testimonial-carousel').length ) {
          $('.testimonial-carousel').slick({
            dots: true,
            arrows: true,
            centerMode: true,
            centerPadding: '0',
            slidesToShow: 3,
            responsive: [
              {
                breakpoint: 767,
                settings: {
                  arrows: true,
                  centerMode: true,
                  centerPadding: '0',
                  slidesToShow: 1
                }
              }
            ]
          });
        }

      }
    },
    'has_gallery': {
      finalize: function() {

        if( $('.page-gallery').length ) {
          var $lg = $('.page-gallery').lightGallery({
            selector: '.gallery-image'
          });

          $('.open-gallery').click(function(e) {
            e.preventDefault();
            $('.page-gallery').find('.gallery-image').first().trigger("click");
          });
        }

      }
    },
    'contact': {
      finalize: function() {
          document.initMap = function() {
            var map;
            var infowindow = new google.maps.InfoWindow();

            function initialize_gmap(bound) {
            	var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

            	var isDraggable = w > 480 ? true : false;

            	var settings = {
            		zoom: 8,
            		center: bound.getCenter(),
            		scrollwheel: false,
            		draggable: isDraggable,
            		mapTypeControl: false,
            		mapTypeControlOptions: {
            			style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
            		},
            		navigationControl: false,
            		navigationControlOptions: {
            			style: google.maps.NavigationControlStyle.SMALL
            		},
            		mapTypeId: google.maps.MapTypeId.ROADMAP
            	};

            	map = new google.maps.Map(document.getElementById("map_canvas"), settings);

            	var center;

            	function calculateCenter() {
            		center = map.getCenter();
            	}
            	google.maps.event.addDomListener(map, 'idle', function() {
            		calculateCenter();
            	});
            	google.maps.event.addDomListener(window, 'resize', function() {
            		map.setCenter(center);
            	});
            }

            function addMarker(point_lat, point_long, address, title) {

            	var point = new google.maps.LatLng(point_lat, point_long);

              var formattedAddress = address.split(' ').join('+');

            	var contentString = '<div id="gmapContent" style="text-align:left;">' +
            		'<a href="https://www.google.com/maps/dir/?api=1&destination=' + formattedAddress + '" target="_blank"><h3>' + title + '</h3></a>' +
            		'<div id="bodyContent"><p>' + address + '</p>' +
                '<a href="https://www.google.com/maps/dir/?api=1&destination=' + formattedAddress + '" target="_blank">' +
                '<i class="fa fa-external-link"></i> Get Directions</a></div></div>';

            	var companyMarker = new google.maps.Marker({
            		position: point,
            		map: map,
            		title: title
            	});

            	google.maps.event.addListener(companyMarker, 'click', function() {
            		infowindow.setContent(contentString);
            		infowindow.open(map, this);
            	});

            }

            var locations = [];

            $('.location-map').each(function() {
            	var location = [];
            	location.push(parseFloat($(this).find('.map-data').find('.lat').data('lat')));
            	location.push(parseFloat($(this).find('.map-data').find('.lng').data('lng')));
            	location.push($(this).find('.map-data').find('.address').data('address'));
            	location.push($(this).find('.map-data').find('.title').data('title'));
            	locations.push(location);
            });

            if ($('#map_canvas').length) {
            	var bound = new google.maps.LatLngBounds();
            	for (i = 0; i < locations.length; i++) {
            		bound.extend(new google.maps.LatLng(locations[i][0], locations[i][1]));
            	}
            	initialize_gmap(bound);
            	for (i = 0; i < locations.length; i++) {
            		addMarker(locations[i][0], locations[i][1], locations[i][2], locations[i][3]);
            	}
            }
          };
          var maps_api_key = 'AIzaSyCQKZ9_Syfis-QcsD0ekU5-d_n4UXs1who';
          var maps_callback = 'document.initMap';
          var maps_api_url = 'https://maps.google.com/maps/api/js';
          var maps_script_tag = document.createElement('script');
          maps_script_tag.setAttribute('type', 'text/javascript');
          maps_script_tag.setAttribute('src', maps_api_url + '?key=' + maps_api_key + '&callback=' + maps_callback);
          maps_script_tag.setAttribute('defer', '');
          maps_script_tag.setAttribute('async', '');
          document.getElementsByTagName('body')[0].appendChild(maps_script_tag);
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
